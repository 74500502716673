<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!--<div class="col-lg-4 col-sm-6">-->
                <div class="col-lg-12 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p>info@neopteraconsulting.com</p>
                    </div>
                </div>
            </div>
<!--
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p>28/A Street, New York City, USA</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p>+1 (321) 984 754</p>
                    </div>
                </div>

            </div> -->
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Neoptera lead and drive a customer focused culture. Let Us Know How can we help you</h2>
            <p>If you would like to get in touch with Neoptera for any request, information or open positions, please submit the below form</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                   
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input  [(ngModel)]="name" type="text" name="name" id="name" class="form-control"  placeholder="Your Name" />
                                </div>
                                
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" (keyup)="checkEmail()" [(ngModel)]="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                                <div *ngIf="displayErrorMsgForName" class="alert alert-danger" role="alert">
                                  Valid Email ID is required.
                                  </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phonenumber"  [(ngModel)]="phoneNum" id="phonenumber"  class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="subject"  [(ngModel)]="subj"  id="subject" class="form-control"  placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message"  class="form-control" [(ngModel)]="msg"  id="message" cols="30" rows="5"  placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div *ngIf="successMsg" class="alert alert-success" role="alert">
                                Request has successfully been submitted.
                              </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="button" class="default-btn page-btn box-btn" (click)="submitForm()">Submit</button>
                            </div>
                        </div>
                   
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>