<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Team</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>People Who Are Behind The Achievements</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g1.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Evana Doe</h3>
                        <p>Founder</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g3.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Maria Doe</h3>
                        <p>Manager</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g2.jpg" alt="team">
                    </div>
                    
                    <div class="team-content">
                        <h3>Smith Doe</h3>
                        <p>CEO</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g5.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Leo Doe</h3>
                        <p>Project Manager</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g4.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Jhon Doe</h3>
                        <p>Marketing Support</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g6.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Brian Cox</h3>
                        <p>Agent Management</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>