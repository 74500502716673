import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestApiService } from '../../services/rest-api-client';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,public restApi: RestApiService) {

    this.activatedRoute.queryParams.subscribe(params => {
      const name = params['name'];
      const email = params['email'];
      const phonenumber = params['phonenumber'];
      const subject = params['subject'];

      const message = params['message'];

      if(email!=null && email!=''){
        
        this.submitQuery(name,email,phonenumber,subject,message);
      }
      else{

        //location.href = "/contact";
      }

      console.log(name);
    });

   }

  name;
  email='';
  msg;
  subj;
  phoneNum;
  displayErrorMsgForName=false;
  ngOnInit(): void {
  }

  submitQuery(name,email,phnnum,subject, body){

    console.log("submit Query "+name)

    location.href = "/contact";
   
  }

  submitForm(){

    console.log("submit data "+this.name)

    this.displayErrorMsgForName=false;
   if(this.email=='' || (!this.email.includes("@"))){


    this.displayErrorMsgForName=true;

    return true;
   }



      this.successMsg=true;

     let data={
      "body": "<b>Hi</b>,<b></br>Please find below contact form submission data."
      
      +"<br></br>"+
      "Name: "+this.name+"<br></br>"+
      "Email: "+this.email+"<br></br>"+
      "Phone Number: "+this.phoneNum+"<br></br>"+
      "Subject: "+"Neoptera Consulting website contact form submission"+"<br></br>"+
      "Message: "+this.msg+"<br></br>",
     
      "subject": "Neoptera Consulting",
      "to": "lalit.g9192@gmail.com",
      "to2": "Noorzadah@hotmail.com",
      "to3": "tiwari.shan@yahoo.ca"
    }


      return this.restApi.triggerEmail(data).subscribe((data: {}) => {
      
        //window.alert("Process Done.");
        this.name='';
        this.email='';
        this.phoneNum='';
        this.subj='';
        this.msg='';
      },(error) => {                              //Error callback
        console.error('error caught in component')
       
      }
    )
  
   
  }

successMsg=false;
  checkEmail(){

      if(this. email.includes("@")){

        this.displayErrorMsgForName=false;
      }
   
  }

}
