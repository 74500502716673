<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <!--<h1>Trusted Effective Service and Solutions</h1>-->
                            <h1>Helping Financial Service Firms Thrive, Through Technological Innovation</h1>
                            <p>Neoptera provides industry leading expertise, to assist financial institutions in streamlining operations, by implementing and executing advanced technological upgrades</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Contact Us</a>
                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Neoptera Provides Industry Leading Execution</h2>
            <p>With several years of experience implementing strategic technology programs, Neoptera helps financial service firms reduce cost and time to market</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!-- <h3>Visual Design</h3>-->
                        <h3>The Real-Time Rail Payment System</h3>
                        <p>Neoptera helps participants to leverage the value of faster, data rich real-time payment system for RTR go live</p>
                    <!--   <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <!--<h3>Development</h3>-->
                        <h3>Financial Transaction Management</h3>
                        <p>Leveraging IBM FTM Framework, Neoptera helps financial institutions integrate, orchestrate and monitor financial transactions</p>
                   <!--     <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!-- <h3>QA Testing</h3>-->
                        <h3>Payment Orchestration</h3>
                        <p>Payment Orchestration Platform can manage your payment service provider relationships, unify all your APIs and give you more control</p>
                   <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!-- <h3>IT Management</h3>-->
                        <h3>Enterprise Integration Bus</h3>
                        <p>Leveraging IBM App Connect Enterprise(IIB), Neoptera allows business information to flow between disparate applications across multiple hardware and software platforms</p>
                    <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                      <!--  <h3> Cyber Security</h3> -->
                        <h3>DevOps Engineering</h3>
                        <p>DevOps engineering ensures continuous delivery of the application across the organization in test and production environments with automated tested deliverables</p>
                   <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                     <!--   <h3> Wireless Connectivity</h3> -->
                        <h3>IT Management</h3>
                        <p>Neoptera collaborates with financial firms to monitor and administer their information technology systems effectively, and to create maintainable processes for various systems</p>
                    <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <!--<h2>Innovative It Helping Service All Over the World</h2>-->
                        <h2>We help our clients and their communities thrive by adapting to rapidly changing technology</h2>
                        <p>Neoptera helps our clients and their communities adapt to how consumers engage with their banks and financial service providers, while promoting innovation in financial products</p>
                       <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form variations passages.</p>-->
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="choose-area ptb-100">
    <div class="container">
       <div class="section-title">
            <span>Choose Aiva</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-group [isOpened]="true" heading="Great Understanding">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.</p>
                            </accordion-group>
                            <accordion-group heading="Update Technology">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.</p>
                            </accordion-group>
                            <accordion-group heading="Experienced Team">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.</p>
                            </accordion-group>
                            <accordion-group heading="Best Quality Service">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.</p>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--
<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>User Testing</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have a Look Our Work Showcase</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>
        <div class="case">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c1.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Joe’s Company Software Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Design">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Development">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Cyber Security">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
        <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
        </div>
    </div>
</section>

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>We Have Also Some Features That Provided by Aiva</h2>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt consectetur, beatae quod eaque reprehenderit non ab quibusdam doloribus voluptatibus! Voluptatum aspernatur quasi id dolore doloremque quo vero</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Freelancing Traning Course</li>
                        <li><i class="flaticon-correct"></i> Technological Consultation</li>
                        <li><i class="flaticon-correct"></i> Monthly Paid Workspace</li>
                        <li><i class="flaticon-correct"></i> IT Learning Institute</li>
                        <li><i class="flaticon-correct"></i> Digital Marketing</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>People Who are Behind the Achievements</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>John Smith</h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Bread Mc</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>John Smith</h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Bread Mc</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/1.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2020</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Joe’s Company Software Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/5.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2020</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/3.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2020</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>IT Software Company Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
        </div>
    </div>
</section>

<section class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>-->