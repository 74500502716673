import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class RestApiService {
  
  // Define API
//  apiURL = 'http://localhost:6080';

  apiURL = 'https://letsditto.co:7443/emailutil';

 
  constructor(private http: HttpClient) { }

  /*========================================
    consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

  

  // HttpClient API post() method => Create App
    deActivateTheEvent(eventid:any,active:any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/event/'+eventid+'/active/'+active,'', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 
  

  // HttpClient API post() method => Create App
    notifyUser(deviceType:any, notifyMeRequest:any): Observable<any> {
    return this.http.post<any>(this.apiURL + '/v1/public/notify-me?deviceType=' + deviceType , JSON.stringify(notifyMeRequest), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  



  triggerEmail(data): Observable<any> {
    return this.http.post<any>(this.apiURL + '/neoptera/email-util', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  


  

   // HttpClient API post() method => Create App
   notifyUserCount(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/v1/public/notify-me')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  



// HttpClient API get() method => Fetch app list
deleteTXN(id:number,userName: any): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/txn/'+id+'/'+userName)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
 


 

  
  
  // Error handling 
  handleError(error:any) {
   
    let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     if(error.status){
       window.alert(errorMessage);
     }else{
      window.alert(errorMessage);
      //window.location.href="https://eventcanopy.uk/admin";
     }
     return throwError(errorMessage);
  }




  dbError(error:any) {
   
  
     return throwError(error);
  }







}