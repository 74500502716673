<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Neoptera provides industry leading execution</h2>
            <p>We bring several decades of cumulative experience in executing critical, large-scale, business programs for financial service firms</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!-- <h3>Visual Design</h3>-->
                       <h3>The Real-Time Rail Payment System</h3>
                       <p>Neoptera helps participants to leverage the value of faster, data rich real-time payment system for RTR go live</p>
                       <!--     <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <!--<h3>Development</h3>-->
                        <h3>Financial Transaction Management</h3>
                        <p>Leveraging IBM FTM Framework, Neoptera helps financial institutions integrate, orchestrate and monitor financial transactions</p>
                   <!--     <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!-- <h3>QA Testing</h3>-->
                       <h3>Payment Orchestration</h3>
                        <p>Payment Orchestration Platform can manage your payment service provider relationships, unify all your APIs and give you more control</p>
                   <!--      <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!-- <h3>IT Management</h3>-->
                       <h3>Enterprise Integration Bus</h3>
                        <p>Leveraging IBM App Connect Enterprise(IIB), Neoptera allows business information to flow between disparate applications across multiple hardware and software platforms</p>
                    <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                       <!--  <h3> Cyber Security</h3> -->
                       <h3>DevOps Engineering</h3>
                        <p>DevOps engineering ensures continuous delivery of the application across the organization in test and production environments with automated tested deliverables</p>
                   <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <!--   <h3> Wireless Connectivity</h3> -->
                        <h3>IT Management</h3>
                        <p>Neoptera collaborates with financial firms to monitor and administer their information technology systems effectively, and to create maintainable processes for various systems</p>
                    <!--    <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

        <!--    <div class="col-lg-12">
                <div class="page-navigation-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/solutions"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/solutions">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/solutions">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/solutions">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/solutions"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>-->
        </div>
    </div>
</section>